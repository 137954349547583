export enum Book_Type {
  SCENE = 1, // 情景演练
  AI = 2, // 实战演练
  LEARN = 3, // 知识学练
  COACH = 4, // 陪练
}

export const typeMap: { [key: number]: string } = {
  [Book_Type.SCENE]: "情景演练",
  [Book_Type.AI]: "实战演练",
  [Book_Type.LEARN]: "知识学练",
  [Book_Type.COACH]: "实战演练"
};

// 练习方式
export enum Learn_Type {
  PHONE = 3, // 电话
  CHAT = 1, // 对讲
  VIDEO = 2, // 视频
}

export const Learn_Type_Map: { [key: number]: string } = {
  [Learn_Type.PHONE]: '电话',
  [Learn_Type.CHAT]: '对讲',
  [Learn_Type.VIDEO]: '视频'
}
