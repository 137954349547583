export const numberToChineseMap: { [key: number]: string } = {
  1: "一",
  2: "二",
  3: "三",
  4: "四",
  5: "五",
  6: "六",
  7: "七",
  8: "八",
  9: "九",
  10: "十",
  11: "十一",
  12: "十二"
};

// 信息展示类型 0不展示 1基本信息 2需求描述 3产品信息 4参考答案
export enum SHOW {
  NONE = 0,
  BASE = 1,
  DEMAND = 2,
  INFO = 3,
  ANSWER = 4,
  INTENSION = 5
}

// 用户角色， 1超管 2管理员 3讲师 4学员 5督学师
export enum ROLE {
  SUPER_ADMIN = 1,
  ADMIN = 2,
  TEACHER = 3,
  STU = 4,
  INSPECTOR = 5
}

// 隐私政策
export const yszcUrl = "https://manerai.cn/privacy";

// 服务协议
export const fwxyUrl = "https://manerai.cn/agreement";
