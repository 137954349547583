import { destroyCookie } from "nookies";

export const getApiHost = () => {
  // 获取当前环境的apiHost
  let url: string;
  if (process.browser) {
    url = "";
  } else {
    url = process.env.NEXT_PUBLIC_DEVELOPMENT_URL as string;
  }
  return url;
};
export const ReLogin = (ctx?: any) => {
  const url = "/login";
  if (ctx && ctx.res) {
    destroyCookie(ctx, process.env.NEXT_PUBLIC_COOKIE_TOKEN_KEY as string);
    destroyCookie(ctx, process.env.NEXT_PUBLIC_COOKIE_remember_KEY as string);

    ctx.res.writeHead(302, {
      Location: url
    });
    ctx.res.end();
  } else {
    destroyCookie(null, process.env.NEXT_PUBLIC_COOKIE_TOKEN_KEY as string);
    destroyCookie(null, process.env.NEXT_PUBLIC_COOKIE_remember_KEY as string);
    window.location.href = url;
  }
};

export const getEnvLocal = (variable: string) => process.env[variable];
export const shuffleArray = (arr: any[]) => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};
